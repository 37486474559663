import React from 'react';
import styled from 'styled-components';
import { Subtitle } from 'views/offer/components/Subtitle';
import { PageHeader } from 'components/page-header';
import { PageWrapper } from 'components/page-wrapper';
import { SectionWrapper } from 'components/section-wrapper';
import { array, arrayOf, string } from 'prop-types';

import { resolveNodeContent } from '../offer/helpers';
import { FaqSection } from '../home/faq-section';
import { BannersSection } from '../offer/components/BannersSection';
import { NewsletterSection } from '../home/newsletter';

const SSectionWrapper = styled(SectionWrapper)`
  margin-bottom: 1rem;
  min-height: auto;
`;

const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TreatmentsSubpage = ({ title, sections, breadcrumbLinks }) => {
  return (
    <PageWrapper>
      <PageHeader links={breadcrumbLinks} withBread={false}>
        {title}
      </PageHeader>
      {sections.map((section, idx) => {
        if (section.type === 'section') {
          return (
            <SSectionWrapper key={idx}>
              <Subtitle>{section.subtitle}</Subtitle>
              {section.nodes.map((node) => resolveNodeContent(node))}
            </SSectionWrapper>
          );
        }
        if (section.type === 'node') {
          return section.nodes;
        }
        if (section.type === 'faq') {
          return (
            <FaqSection faqData={section.nodes} />
          );
        }
        if (section.type === 'bannerSection') {
          return (
            <ParentContainer key={idx}>
              <BannersSection customMargin="4rem 0 2rem" />
            </ParentContainer>
          );
        }
        if (section.type === 'newsletter') {
          return (
            <NewsletterSection />
          );
        }
        return null;
      })}
    </PageWrapper>
  );
};

TreatmentsSubpage.propTypes = {
  title: string.isRequired,
  sections: arrayOf({
    type: string.isRequired,
    subtitle: string.isRequired,
    nodes: arrayOf({
      type: string.isRequired,
      content: string.isRequired,
    }),
  }),
  breadcrumbLinks: array.isRequired,
};
